









































































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { formatTimestamp } from '@/utils/utils'

@Component({
  name: 'Trend',
})
export default class Index extends VueBase {
  @Prop() project!: any

  private formatTimestamp(timestamp: number) {
    return formatTimestamp(timestamp)
  }

  private toProjectDetail(project: any) {
    this.$router.push({
      path: '/project/appDetail/' + project.project_id,
    })
  }
}
